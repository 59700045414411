import orderLogic from 'public/src/pages/common/orderLogic'
import schttp from 'public/src/services/schttp'
import { template, isNumber } from '@shein/common-function'

const isBrowser = typeof window !== 'undefined'
export const UA = isBrowser ? window.navigator.userAgent.toLowerCase() : ''
export const isAndroid = UA && UA.indexOf('android') > 0
// fix: 兼容ios13后ipad使用桌面端wkWebview，导致ua中不存在ipad标识，window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1
export const isIOS = (UA && /iphone|ipad|ipod|ios/.test(UA)) || (isBrowser && window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1)

export const handlerDate = (date, localData) => {
  return orderLogic.orderDateFormat(date, false, localData)
}

// 格式化时间
export const formatTime = (time, localData = null) => {
  let timeString = ''
  if (time) {
    if (/^\d{10,13}$/.test(time)) {
      timeString = handlerDate(time, localData)
    } else if (/^\d{10,13}-\d{10,13}$/.test(time)) {
      const transportTime = time.split('-')
      const startTime = handlerDate(transportTime[0], localData)
      const endTime = handlerDate(transportTime[1], localData)
      timeString = `${startTime} ~ ${endTime}`
    } else {
      timeString = time
    }
  }
  return timeString
}

// 格式化双时效文案
export const formatDoubleTime = (params = {}) => {
  let {
    originShippingDesc,
    originDeliveryDesc,
    transportTimeType,
    transportTime,
    subTransportTime,
    useEnum = false,
    localData = null
  } = params
  if (!originShippingDesc || !originDeliveryDesc || !subTransportTime) {
    return ''
  }
  if (useEnum) {
    transportTimeType = transportTimeType - 1
  }

  if (transportTimeType == 0) {
    const shippingDesc = originShippingDesc.replace('%s', transportTime)
    const deliveryDesc = originDeliveryDesc.replace(
      '%s',
      formatTime(subTransportTime, localData)
    )

    return `${shippingDesc} (${deliveryDesc})`
  } else {
    const shippingDesc = originShippingDesc.replace('%s', subTransportTime)
    const deliveryDesc = originDeliveryDesc.replace(
      '%s',
      formatTime(transportTime, localData)
    )
    return `${deliveryDesc} (${shippingDesc})`
  }
}

const replaceQuickShip = (quickShipDesc = '', quickShipWord = '') => {
  if (typeof quickShipDesc != 'string') return ''
  return quickShipDesc.replace(/{QSname}/g, quickShipWord)
}

// 根据配置格式化quick_ship_desc文案
export const formatQuickShipDesc = (params = {}) => {
  const { 
    quickShipDesc = '', 
    countryId = '', 
    isCurrentSite = false, 
    language = {}, 
    quickShipAbt = {}, 
    ORDER_QUICK_SHIP_WORD = {} 
  } = params
  if (!quickShipDesc || typeof quickShipDesc !== 'string') return ''
  if (quickShipAbt.orderQuickshipStyle == 'on') {
    // 如果abt开启、订单是当前站点且abt中orderQuickshipWord有值则取abt的值
    const orderQuickShipWord = quickShipAbt.orderQuickshipWord
    if (isCurrentSite && orderQuickShipWord > 0) {
      return replaceQuickShip(quickShipDesc, 
        template(orderQuickShipWord, language.SHEIN_KEY_PWA_30600)
      )
    }
    // 如果abt开启、订单非当前站点且阿波罗中有与当前订单国家匹配的配置，则取阿波罗的值
    const apolloItem = ORDER_QUICK_SHIP_WORD?.[countryId] || {}
    if (!isCurrentSite && apolloItem.orderQuickshipWord > 0) {
      return replaceQuickShip(quickShipDesc, 
        template(apolloItem.orderQuickshipWord, language.SHEIN_KEY_PWA_30600)
      )
    }
  }
  return replaceQuickShip(quickShipDesc, language.SHEIN_KEY_PWA_22276)
}

// 根据配置格式化quick_ship标签文案
export const formatQuickShipLabel = (params = {}) => {
  const { 
    countryId = '', 
    isCurrentSite = false, 
    language = {}, 
    quickShipAbt = {}, 
    ORDER_QUICK_SHIP_WORD = {} 
  } = params
  if (quickShipAbt.orderQuickshipStyle == 'on') {
    // 如果abt开启、订单是当前站点且abt中orderQuickshipWord有值则取abt的值
    const orderQuickShipWord = quickShipAbt.orderQuickshipWord
    if (isCurrentSite && orderQuickShipWord > 0) {
      return template(
        quickShipAbt.orderQuickshipWord,
        language.SHEIN_KEY_PWA_30600
      )
    }
    // 如果abt开启、订单非当前站点且阿波罗中有与当前订单国家匹配的配置，则取阿波罗的值
    const apolloItem = ORDER_QUICK_SHIP_WORD?.[countryId] || {}
    if (!isCurrentSite && apolloItem.orderQuickshipWord > 0) {
      return template(
        apolloItem.orderQuickshipWord,
        language.SHEIN_KEY_PWA_30600
      )
    }
  }
  return language.SHEIN_KEY_PWA_22276
}

// 获取催派的时间类型
export const getTimeType = (packageInfo = {}) => {
  let {
    time_type: timeType, // 运输时间: qs和大件:1:运输时间 2:送达时间 | 普通时效:0:运输时间 1:送达时间
    type, // 时效类型：0-普通时效 1-qs时效 2-大件时效
  } = packageInfo || {}
  if (timeType == 999) return timeType
  // 如果不是普通时效，使用新枚举字段
  if (isNumber(type) && type != 0) {
    timeType -= 1
  }
  return timeType
}

export const downloadPdfEvt = async (url = '', newWindow) => {
  if (isIOS) {
    if (newWindow) {
      newWindow.location = url
    } else {
      window.open(url, '_blank')
    }
    return 
  }

  const data = await schttp({
    method: 'GET',
    url: '/api/orders/base/image/get',
    responseType: 'blob',
    params: {
      url
    }
  })
  
  const blob = new Blob([data], { type: 'application/pdf' })
  const fileName = url.split('/').pop().split('?')[0]

  if (window.showSaveFilePicker) {
    try {
      // 请求用户选择保存位置
      const handle = await window.showSaveFilePicker({
        suggestedName: fileName,
        types: [{
          description: 'PDF Files',
          accept: { 'application/pdf': ['.pdf'] },
        }]
      })
      // 写入文件
      const writable = await handle.createWritable()
      await writable.write(blob)
      await writable.close()
    } catch (err) {
      window.open(url, '_blank')
      console.error('Error saving PDF file:', err)
    }
  } else {
    const a = document.createElement('a')
    const URL = window.URL || window.webkitURL
    const herf = URL.createObjectURL(blob)
    a.href = herf
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(herf)
  }
}



