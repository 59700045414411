<template>
  <div class="return-time">
    <div
      class="return-time__desc"
      @click="handleExpirePop(true)"
    >
      {{ timeDesc }}
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue'

const props = defineProps({
  packageReturnTime: {
    type: Object,
    default: () => {}
  },
  language: {
    type: Object,
    default: () => {}
  },
  orderReturnTime: {
    type: Object,
    default: () => {}
  },
  orderInfo: {
    type: Object,
    default: () => {}
  },
})

const showExpirePop = ref(false)
const handleExpirePop = (val) => {
  const { over_time_limit } = props.orderInfo
  if (Number(over_time_limit)) {
    showExpirePop.value = val
  }
}

const timeDesc = computed(() => {
  if (props.packageReturnTime?.timeDesc) {
    return `${props.language.SHEIN_KEY_PWA_33740}: ${props.packageReturnTime.timeDesc}`
  }
  return props.orderReturnTime?.timeDesc || ''
})
</script>
<style lang='less' scoped>
.return-time {
  display: inline-block;
  width: 100%;
  padding: 0 24/75rem 16/75rem;
  &__desc {
    background: @sui_color_gray_weak2;
    padding: 16/75rem;
    line-height: 1.2;
  }
}
</style>
